
import React, { useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    List,
    ListItem,
    Text,
    Flex,
    SimpleGrid,
    Stat,
    HStack,
    Link,
    Input,
    Center,
    Icon,
    Modal,
    ModalOverlay,
    ModalContent,
    // ModalHeader,
    // ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Stack,
    Badge,
    useColorModeValue,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Image,
    VStack,
} from '@chakra-ui/react';
import { useHistory, useLocation } from "react-router-dom";
import { FaCamera, FaCheckCircle, FaShippingFast, FaSignInAlt, FaTicketAlt } from "react-icons/fa";
import Loading from "../utils/Loading";
import * as CONFIG from "../config";
import escapeRegExp from 'escape-string-regexp'
import VoucherResultsApi from "./VoucherResultsApi";
import { getOrSetVouchercode } from "../utils/SessionToken";
import { getVoucherDetail, voucherList } from "../utils/ApiUtilities";
import { getImgFlag, getTicketPdfUrl } from "../utils/AppUtilities";
import { setScrollPos, setSelectedaccordion, setSelectedvoucher, setWhenList, setuserData, setUiVersion } from "../redux/actions/appActions";
import { ButtonBox } from "./HomeButton";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, subDays, format } from 'date-fns'
import it from 'date-fns/locale/it';
import ActionButtons from "./ActionButton";


function VoucherList(props) {
    // console.log('VoucherList');
    const { selectedAccordion, selectedVoucher, whenList, scrollPos, userData, voucherQuery } = useSelector((state) => state.user);
    const { hash } = useLocation();
    const dispatch = useDispatch();

    const history = useHistory();
    const { isOpen: isVoucherOpen, onOpen: onVoucherOpen, onClose: onVoucherClose } = useDisclosure()
    const { isOpen: isTicketOpen, onOpen: onTicketOpen, onClose: onTicketClose } = useDisclosure()
    // const { results, elaborated } = props;
    // console.log('Scan done', results);
    // const descFontSize = 'xs';
    // const valueFontSize = 'md';
    const checkedColor = useColorModeValue('green.300', 'green.800');
    const orphanCheckedColor = useColorModeValue('blue.200', 'cyan.800');
    const unCheckedColor = useColorModeValue('gray.100', 'gray.600');
    const iconCheckedColor = useColorModeValue('green.600', 'green.900');
    const iconUnCheckedColor = useColorModeValue('gray.600', 'yellow.700');
    const hoverCheckedColor = useColorModeValue('green.100', 'green.600');
    const hoverOrphanCheckedColor = useColorModeValue('blue.100', 'cyan.600');
    const hoverUnCheckedColor = useColorModeValue('gray.300', 'yellow.500');
    const fontColor = useColorModeValue('black.100', 'whiteAlpha.900');
    const borderColor = useColorModeValue('black', 'white')
    const searchBoxColor = useColorModeValue('gray.100', 'gray.700')
    const searchBoxColorActive = useColorModeValue('cyan.500', 'cyan.300')
    const searchBoxFontColor = useColorModeValue('white', 'white')
    const ticketColor = useColorModeValue('blue.500', 'gray.400');
    const fastcheckinColor = useColorModeValue('green.400', 'green.700');
    const checkedIcon = FaCheckCircle;
    const unCheckedIcon = FaSignInAlt;
    const ticketIcon = FaTicketAlt;
    const checkedHeight = 5;
    const unCheckedHeight = 5;
    const ticketHeight = 5;
    const fastcheckinHeight = 4;


    const [vlist, setVlist] = useState('');
    const [checkedlist, setCheckedlist] = useState(0);
    const [uncheckedlist, setUncheckedlist] = useState(0);
    const [loading, setLoading] = useState(false);
    const [previous, setPrevious] = useState('ieri');
    const [next, setNext] = useState('dopodomani');
    const [when, setWhen] = useState(whenList);
    const [query, setQuery] = useState(undefined);
    const [results, setResults] = useState(false);
    const [pdfFileUrl, setPdfFileUrl] = useState(null);
    // const when = '';
    // const [showScanner, setShowScanner] = useState(false);
    // const [startDate, setStartDate] = useState(whenListDatePicker);
    const [buttonColorDate, setButtonColorDate] = useState('dateButton');
    
    const ieri = subDays(new Date(), 1);
    const oggi = new Date();
    const domani = addDays(new Date(), 1);

    // const voucherRef = React.useRef(null);
    const getVoucherList = React.useCallback(
        async (whenSelected) => {
            // console.log('---------call getVoucherList', whenSelected);
            setLoading(true);
            compareDateForColor(whenSelected);
            setWhen(format(whenSelected, 'dd/MM/yyyy'));
            // setWhenList(when);
            // if (whenList === when) {
            //     console.log('whenList === when', (whenList === when));
            //     setTimeout(() => {
            //         // window.scrollTo(0, scrollPos);
            //         window.scrollTo({
            //             top: scrollPos,
            //             left: 0,
            //             behavior: "smooth",
            //         });
            //     }, 200);
            // } else {
            //     dispatch(setScrollPos(1));
            // }
            // const res = await voucherList(whenSelected);
            const res = await voucherList(format(whenSelected, 'dd/MM/yyyy'));
            
            // console.log(res.user ? res.user : undefined);
            dispatch(setuserData(res.user));
            dispatch(setUiVersion(res.version));

            setResults(res);
            // console.log('res.voucher', res.vouchers);
            // res.vouchers = [...res.vouchers, ...res.orphanvoucher];
            // console.log('res.vouchers', res.vouchers);
            // console.log('Checked voucher', res.vouchers.filter((voucher) => voucher.checked));
            res.vouchers ? setCheckedlist(res.vouchers.filter((voucher) => voucher.checked).length) : setCheckedlist(0);
            res.vouchers ? setUncheckedlist(res.vouchers.filter((voucher) => !voucher.checked).length) : setUncheckedlist(0);
            setVlist(res.vouchers);
            dispatch(setWhenList(whenSelected));
            setLoading(false);
        },
        []
    );

    useEffect(() => {
        if (when === undefined || scrollPos[when] === undefined) return;
        setTimeout(() => {
            // console.log('whenList', whenList)
            // window.scrollTo(0, scrollPos);
            // console.log('scrollPos', scrollPos[when]);
            // console.log('useEffect handleScroll', when + ' ' + scrollPos[when]);
            window.scrollTo({
                top: scrollPos[when],
                left: 0,
                behavior: "smooth",
            });
        }, 400);
    }, [when]);

    const setWinPosition = React.useCallback(
        async (when) => {
            // console.log('setWinPosition', when);
            // console.log('scrollPos', scrollPos);
            const position = [];
            position[when] = window.pageYOffset;
            dispatch(setScrollPos({ ...scrollPos, ...position }));
        },
        [getVoucherList, when]
    );

    useEffect(() => {
        // console.log('---------getVoucherList useEffect whenList', whenList);
        // getVoucherList(whenList).then(() => { handleScroll() });
        getVoucherList(whenList);
    }, []);

    const handleCheckin = async (voucher) => {
        if (voucher === 'undefined') {
            history.push(CONFIG.HOME_URL);
        }
        // if (voucher.vouchercode === undefined || voucher.vouchercode === 'undefined') {
        //     if (voucher.id <= 0 || voucher.id === undefined || voucher.id === 'undefined') {
        //         setResults(null);
        //         history.push(CONFIG.HOME_URL);
        //         return;
        //     }
        // }
        // console.log('---------getVoucherList handleCheckin voucher', voucher);
        setWinPosition(when);
        // console.log('voucher', voucher);
        dispatch(setSelectedvoucher('#voucher' + voucher.id));
        // const result = await getVoucherDetail(voucher.orphan ? voucher.id : voucher.vouchercode);
        // const result = await getVoucherDetail(voucher.id);
        const result = await getVoucherDetail(voucher.orphan ? CONFIG.orphanPrefix+voucher.id : voucher.id);
        setResults(result);
        // console.log('result.voucher.id', result.voucher.id);
        // await getOrSetVouchercode(voucher.vouchercode);
        await getOrSetVouchercode(voucher.id);
        // result.success && result.voucher.valid && !result.voucher.checked ? history.push(CONFIG.VOUCHER_CHECK_IN_FROM_LIST) : onVoucherOpen();
        result.success && result.voucher.valid && !result.voucher.checked ? history.push(CONFIG.VOUCHER_CHECK_IN_FROM_LIST) : result.voucher.id !== undefined && onVoucherOpen();
        // result.success && result.voucher.valid && !result.voucher.checked ? setShowScanner(true) : onVoucherOpen();
        // voucher.checked ? onOpen() : history.push(CONFIG.VOUCHER_CHECK_IN_FROM_LIST);
        // setViewDetail(true);
        // onOpen();
        // setLoading(true);
    }

    const handleClickAccordion = async (accordionId) => {
        // console.log('accordionId', accordionId);
        setWinPosition(when);
        const accordion = [];
        accordion[when] = accordionId;
        // selectedAccordion[when].push(accordionId)
        // console.log('accordion', accordion);
        dispatch(setSelectedaccordion({ ...selectedAccordion, ...accordion }));
    }

    const compareDateForColor = async (data) => {
        if (format(data, 'dd/MM/yyyy') === format(ieri, 'dd/MM/yyyy')) {
            setButtonColorDate('dateButtonUnselected');
            return;
        }
        if (format(data, 'dd/MM/yyyy') === format(oggi, 'dd/MM/yyyy')) {
            setButtonColorDate('dateButtonUnselected');
            return;
        }
        if (format(data, 'dd/MM/yyyy') === format(domani, 'dd/MM/yyyy')) {
            setButtonColorDate('dateButtonUnselected');
            return;
        }
    }

    // const handleClickAccordion = React.useCallback(
    //     async (accordionId) => {
    //         setWinPosition(when, whenList);
    //         dispatch(setSelectedaccordion(accordionId));
    //     },
    //     [dispatch]
    // );

    const handleCloseModal = async () => {
        // setViewDetail(false);
        onVoucherClose();
        // getVoucherList(whenList);
    }

    const handleDatePicker = async (date) => {
        setButtonColorDate('dateButton');
        // console.log(format(date, 'dd/MM/yyyy'));
        // dispatch(setWhenListDatepicker(date));
        // setStartDate(date);
        // getVoucherList(format(date, 'dd/MM/yyyy'));
        getVoucherList(date);
    }

    const handleQuery = async (query) => {
        setQuery(query);
        // dispatch(setSearchVoucherQuery(query))
        // console.log('when', when)
        // const res = await voucherList(when);
        // console.log(res.vouchers);
        // setVlist(res.vouchers);
    };

    const viewPdf = async (ticketPdf) => {
        const pdfUrl = await getTicketPdfUrl(ticketPdf)
        // console.log("Apro " + pdfUrl);
        // window.open(pdfUrl, "_blank");
        setPdfFileUrl(pdfUrl);
        onTicketOpen();
    }

    const description = (voucher) => {
        const len = 48;
        let desc = voucher.description;
        // desc = desc + (voucher.numcarrier > 0 ? ' - ' + voucher.numcarrier + ' ' + voucher.shortcarriername : '') + (voucher.orphan ? '' : ' - ' + voucher.pax + ' pax')
        desc = desc + (voucher.numcarrier > 0 ? ' - ' + voucher.numcarrier + ' ' + voucher.shortcarriername : '')
        return desc;
        // return desc.length <= len ? desc : desc.substring(0, len - 3) + "…";
    }

    let filteredList
    if (query) {
        hour = 0;
        hourFormatted = 0;
        item = [];
        //case sensitive
        // const searchMatch = new RegExp(escapeRegExp(this.state.query))
        //case insensitive
        const searchMatch = new RegExp(escapeRegExp(query), 'i')
        // filteredList = vlist.filter((voucher) => searchMatch.test(voucher.client) || searchMatch.test(voucher.client))
        filteredList = vlist ? vlist.filter((voucher) => searchMatch.test(voucher.client) || searchMatch.test(voucher.client)) : '';
    } else {
        // console.log('else');
        filteredList = vlist
    }

    const buttonColor = 'gray';
    const buttonColorSelected = 'orange';
    // const buttonColorDate = 'dateButton';

    var hour = 0;
    var preHour = 0;
    var hourFormatted = 0;
    var preHourFormatted = 0;
    var prevStartHour = 0;
    var item = [];
    var addHourFormatted = [];
    var preAddHourFormatted = [];
    var totalVoucher = 0;
    var checkedVoucher = 0;
    var uncheckedVoucher = 0;
    var checkUncheckText = '';
    
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        // <Button ref={ref}>{value}</Button>
        <Button size='xs' variant={buttonColorDate} onClick={onClick} ref={ref}>
            {value}
        </Button>
    ));

    // console.log('selectedAccordion', selectedAccordion);

    return (
        // showScanner === true ? 
        //     <Html5QrcodeReader _results={results} _elaborated={true} _aquired={true} />
        // :
        <>
            <Box maxW="3xl" mx={'auto'} pt={0} px={{ base: 2, sm: 12, md: 17 }}>
                {/* <Center mb={'6px'}> */}
                    {/* <Text fontSize={'18px'} mb={'6px'}> */}
                    {/* <Badge variant='solid' colorScheme='black' fontSize='1.2em'>
                            Voucher di <b>{whenList}</b>{': '}{vlist ? vlist.length : 0}
                        </Badge> */}
                    {/* </Text> */}
                    {/* </Center> */}
                <SimpleGrid columns={4} spacingX='5px' maxWidth='380px' mx={'auto'} mt='5px' mb='15px'>
                    {/* <Box mb={'10px'}> */}
                    {/* <Button onClick={() => (getVoucherList(previous))} size='xs' variant={whenList === 'ieri' ? buttonColorSelected : buttonColor}>Ieri</Button>
                    <Button onClick={() => (getVoucherList('oggi'))} size='xs' variant={whenList === 'oggi' ? buttonColorSelected : buttonColor}>Oggi</Button>
                    <Button onClick={() => (getVoucherList('domani'))} size='xs' variant={whenList === 'domani' ? buttonColorSelected : buttonColor}>Domani</Button> */}
                    <Button onClick={() => (getVoucherList(ieri))} size='xs' variant={format(whenList, 'dd/MM/yyyy') === format(ieri, 'dd/MM/yyyy') ? buttonColorSelected : buttonColor}>Ieri</Button>
                    <Button onClick={() => (getVoucherList(oggi))} size='xs' variant={format(whenList, 'dd/MM/yyyy') === format(oggi, 'dd/MM/yyyy') ? buttonColorSelected : buttonColor}>Oggi</Button>
                    <Button onClick={() => (getVoucherList(domani))} size='xs' variant={format(whenList, 'dd/MM/yyyy') === format(domani, 'dd/MM/yyyy') ? buttonColorSelected : buttonColor}>Domani</Button>
                    
                    {/* <Button onClick={() => (getVoucherList(next))} size='xs' variant={whenList === 'dopodomani' ? buttonColorSelected : buttonColor}>Dopodomani</Button> */}
                    <DatePicker 
                        dateFormat="dd.MM.yyyy"
                        selected={whenList}
                        onChange={(date) => handleDatePicker(date)}
                        customInput={<DatePickerCustomInput />}
                        excludeDateIntervals={[
                            { start: addDays(new Date(), 2), end: addDays(new Date(), 3650) },
                        ]}
                        locale={it}
                    />
                    {/* <Button onClick={() => (getVoucherList(next))} size='xs' variant={whenList === 'date' ? buttonColorSelected : buttonColor}>Data</Button> */}
                    {/* <Button ></Button> */}
                    {/* <Button ><FaSignOutAlt size={'1em'} /></Button> */}
                {/* </Box> */}
                </SimpleGrid>
                <Center mb={'4px'}>
                    <Stack direction='row'>
                        <Badge color={fontColor} bg={checkedColor} variant='solid' fontSize='1em' textTransform={'capitalization'} p={'3px'} pl={'10px'} pr={'10px'} rounded={''}>
                            {checkedlist} su {vlist ? vlist.length : 0} completi
                        </Badge>
                        {/* <Text as="span">-</Text> */}
                        <Badge color={fontColor} bg={unCheckedColor} variant='solid' fontSize='1em' textTransform={'capitalization'} p={'3px'} pl={'10px'} pr={'10px'} rounded={''}>
                            {uncheckedlist} da completare
                        </Badge>
                        {/* - <Text as="span" bgColor={ checkedColor }>{checkedlist}</Text>/{uncheckedlist}) */}
                        {/* ) */}
                    </Stack>
                </Center>
                <Input
                    placeholder="Cerca cliente"
                    // size='md'
                    variant="outline"
                    value={query}
                    onChange={(event) => handleQuery(event.target.value)}
                    mt={'12px'}
                    mb={query ? '12px' : '10px'}
                    // width={'90%'}
                    borderColor={borderColor}
                    background={query ? searchBoxColorActive : searchBoxColor}
                    color={searchBoxFontColor}
                    height={query ? '38px' : '22px' }
                    fontWeight={'bold'}
                />
                {/* <Box>
                    <Input
                        placeholder="Cerca cliente"
                        size='sm'
                        variant="outline"
                        value={query}
                        onChange={(event) => handleQuery(event.target.value)}
                        mt={'8px'}
                    />
                </Box> */}
                
                {loading ? <Loading label='Loading...' size='120px' /> :
                    <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 4, lg: 8 }}>
                        <Stat
                            // px={{ base: 4, md: 4 }}
                            py={'1'}
                            // shadow={'xl'}
                            border={'0px solid'}
                            rounded={'lg'}
                            opacity={10}
                            cursor='hand'
                        >
                            {/* <Flex justifyContent={'space-between'} mb={'10px'} border={'0px solid'} p={'4px'} rounded={'lg'}> */}
                            {/* <HStack w={'100%'} > */}
                            {/* <Box ><Text fontSize={'18px'} m={'10px'}>Voucher di <b>{whenList}</b>: ({vlist ? vlist.length : 0})</Text></Box> */}
                            {/* </HStack> */}
                            {/* </Flex> */}
                            {
                                filteredList ?
                                    <>
                                    {/* {
                                            filteredList.map((voucher, index) => {
                                                if (hour === 0 || hour === '0000') {
                                                    hour = voucher.starthour;
                                                    hourFormatted = voucher.starthourformatted;
                                                }
                                        })
                                    } */}
                                        <Accordion 
                                            allowToggle
                                            allowMultiple
                                            defaultIndex={selectedAccordion[when] !== undefined ? selectedAccordion[when] : [0]}
                                            onChange={(index) => handleClickAccordion(index)}
                                            >
                                            {
                                            filteredList.map((voucher, index) => {
                                                // console.log(voucher.starthour);
                                                // { const bgColor = voucher.checked ? hoverCheckedColor : hoverUnCheckedColor }
                                                item = [...item,
                                                    
                                                    <Link onClick={() => handleCheckin(voucher)} key={voucher.id} id={'voucher'+voucher.id}>
                                                        <Flex justifyContent={'space-between'}
                                                            mb={'10px'}
                                                            border={'1px solid'}
                                                            p={'4px'}
                                                            rounded={'lg'}
                                                            key={index}
                                                            // lineHeight={'12px'}
                                                            // bg={'white'}
                                                            color={fontColor}
                                                            _hover={{
                                                                bg: voucher.checked ? voucher.orphan ? hoverOrphanCheckedColor : hoverCheckedColor : hoverUnCheckedColor,
                                                            }}
                                                            bg={voucher.checked ? voucher.orphan ? orphanCheckedColor : checkedColor : unCheckedColor}
                                                        >
                                                            <HStack w={'100%'} h={voucher.note ? { base: 12, md: 16 } : { base: 8, md: 10 }}>
                                                                <VStack h={'100%'} spacing={'2px'}>
                                                                    <Box fontSize={'8px'} textAlign={'center'} alignContent={'center'} lineHeight={'8px'} sx={{'white-space': 'nowrap'}}>
                                                                        <code>{voucher.starthourformatted}</code>
                                                                        {voucher.orphan ? '' : [<br />, voucher.pax + ' pax']}
                                                                    </Box>
                                                                    {userData.showLanguage &&
                                                                        <Box textAlign={'center'} alignContent={'center'} sx={{ 'align-items': 'center', 'diplay': 'flex', 'justify-content': 'center' }} display={'flex'} height={'100%'}>
                                                                            {getImgFlag(voucher.language) ? <Image src={getImgFlag(voucher.language)} alt={voucher.language} width={'20px'} sx={{ 'opacity': '0.8' }} borderRadius={'3px'} /> : voucher.language}
                                                                            {/* 
                                                                
                                                                {voucher.fastcheckin &&
                                                                        <Icon
                                                                            // onClick={() => handleCheckin(voucher)}
                                                                            as={FaShippingFast}
                                                                            w={fastcheckinHeight}
                                                                            h={fastcheckinHeight}
                                                                            color={fastcheckinColor}
                                                                            mr={'4px'}
                                                                        />
                                                                } */}
                                                                        </Box>
                                                                    }
                                                                </VStack>
                                                                {/* <Box textAlign={'left'} fontSize={'12px'} fontWeight={'bold'}><Text>{voucher.description} - {voucher.pax} pax - {voucher.touroperator}</Text></Box> */}
                                                                <Box textAlign={'center'} lineHeight={{ base: '15px', md: '20px' }} w={'100%'}>
                                                                    <Text align={'center'} fontSize={{ base: '18px', md: '22px' }} mt={'2px'} mb={'4px'} fontWeight={'bold'} textTransform={'uppercase'} noOfLines={1}>
                                                                        {voucher.orphan ? voucher.vouchercode : voucher.client}
                                                                    </Text>
                                                                    <Text fontSize={{ base: '13px', md: '15px' }} noOfLines={1}>
                                                                        {/* {voucher.description.replace('PARTENZA', '')} - {voucher.pax} pax */}
                                                                        {/* {voucher.description}{voucher.numcarrier > 0 ? ' - ' + voucher.numcarrier + ' ' + voucher.shortcarriername : ''}{voucher.orphan ? '' : ' - '+voucher.pax+' pax'} */}
                                                                        {description(voucher)}
                                                                        {/* {voucher.description.substring(0, 10) + '...' + voucher.description.substring(voucher.description.length - 10)} - 2 g - {voucher.pax} pax */}
                                                                    </Text>
                                                                    {voucher.note ?
                                                                    <Text fontSize={{ base: '12px', md: '14px' }} noOfLines={1} fontStyle={'italic'}>
                                                                        {voucher.note}
                                                                    </Text>
                                                                     : ''}
                                                                </Box>
                                                                {/* <Spacer /> */}
                                                                {/* {voucher.checked && */}
                                                                {/* <Box textAlign={'right'} fontSize={'10px'} lineHeight={'10px'}>
                                                                    <Icon
                                                                        onClick={() => handleCheckin(voucher)}
                                                                        as={chckedPhoto}
                                                                        w={voucher.checked ? checkedHeight : unCheckedHeight}
                                                                        h={voucher.checked ? checkedHeight : unCheckedHeight}
                                                                        color={'green.900'}
                                                                        // mr={'4px'}
                                                                    />
                                                                </Box> */}
                                                                {/* } */}
                                                                {/* {voucher.checked &&
                                                                    <Box textAlign={'right'} fontSize={'10px'} lineHeight={'10px'}>
                                                                        <Icon
                                                                            // onClick={() => handleCheckin(voucher)}
                                                                            as={voucher.checked ? checkedIcon : unCheckedIcon}
                                                                            w={voucher.checked ? checkedHeight : unCheckedHeight}
                                                                            h={voucher.checked ? checkedHeight : unCheckedHeight}
                                                                            color={voucher.checked ? iconCheckedColor : iconUnCheckedColor}
                                                                            mr={'4px'}
                                                                        />
                                                                    </Box>
                                                                } */}
                                                                {voucher.tickets !== null &&
                                                                    <Box textAlign={'right'} fontSize={'10px'} lineHeight={'10px'}>
                                                                        <Icon
                                                                            // onClick={() => handleCheckin(voucher)}
                                                                            as={ticketIcon}
                                                                            w={ticketHeight}
                                                                            h={ticketHeight}
                                                                            color={ticketColor}
                                                                            mr={'4px'}
                                                                        />
                                                                    </Box>
                                                                }
                                                                
                                                            </HStack>
                                                        </Flex>
                                                    </Link>
                                                ]
                                                if (hour === 0 || hour === '0000') {
                                                    hour = voucher.starthour;
                                                    hourFormatted = voucher.starthourformatted;
                                                    }
                                                // console.log(index);
                                                // console.log('hour', hour)
                                                // console.log('voucher.starthour', voucher.starthour)
                                                // console.log('hourFormatted', hourFormatted)
                                                // console.log('preHourFormatted', preHourFormatted)
                                                // console.log('filteredList.length', filteredList.length)
                                                if (filteredList.length < (userData && userData.minShowAccordion)) {
                                                    // console.log(index-1);
                                                    return item.pop();
                                                }
                                                totalVoucher += 1
                                                checkedVoucher += voucher.checked ? 1 : 0;
                                                uncheckedVoucher += voucher.checked ? 0 : 1;
                                                // console.log('addHourFormatted', index + ') ' + 'hour:' + hour + 'voucher.starthour:' + voucher.starthour);
                                                if ((hour !== voucher.starthour || filteredList.length === index + 1) && hour !== '0000' && ((item.length > userData.minGroupAccordion || filteredList.length === index + 1) && (prevStartHour !== voucher.starthour || filteredList.length === index + 1))) {
                                                    
                                                    // console.log(index+'---------------PRINT--------------')
                                                    // if (hour !== 0) {
                                                    // addHourFormatted[voucher.starthour] = voucher.starthourformatted;
                                                    preAddHourFormatted = addHourFormatted.pop()
                                                    addHourFormatted = []
                                                    preHourFormatted = hourFormatted
                                                    hour = voucher.starthour;
                                                    hourFormatted = voucher.starthourformatted;
                                                    // console.log('preAddHourFormatted', preAddHourFormatted)
                                                    // hourFormatted = preAddHourFormatted.pop();
                                                    // console.log('hourFormatted', hourFormatted)
                                                    // console.log('preAddHourFormatted', preAddHourFormatted.reverse())
                                                    // hourFormatted = preAddHourFormatted.pop();
                                                    // console.log('hourFormatted', hourFormatted)
                                                    // console.log('preAddHourFormatted', preAddHourFormatted)
                                                    let itemToRender = item;
                                                    // console.log('--------------- addHourFormatted', addHourFormatted);
                                                    // console.log('itemToRender.length', itemToRender.length)
                                                    item = '';
                                                    if (filteredList.length !== index + 1) {
                                                        item = [...item, itemToRender.pop()];
                                                        //Tolgo il valore dell'utimo voucher
                                                        checkedVoucher -= voucher.checked ? 1 : 0;
                                                        uncheckedVoucher -= voucher.checked ? 0 : 1;
                                                        totalVoucher -= 1;
                                                        checkUncheckText = checkedVoucher + '/' + totalVoucher;
                                                        //Ripristino il valore dell'ultimo
                                                        checkedVoucher = voucher.checked ? 1 : 0;
                                                        uncheckedVoucher = voucher.checked ? 0 : 1;
                                                        totalVoucher = 1;
                                                    } else {
                                                        checkUncheckText = checkedVoucher + '/' + totalVoucher;
                                                        checkedVoucher = 0;
                                                        uncheckedVoucher = 0;
                                                        totalVoucher = 0;
                                                    }
                                                    if (itemToRender.length > 0) {
                                                        return (
                                                        <AccordionItem border={0} id={'acc' + index} key={'acc' + index} >
                                                            <AccordionButton
                                                                color={'gray.200'}
                                                                bg={'gray.500'}
                                                                _expanded={{ bg: 'gray.600', color: 'gray.300' }}
                                                                _hover={{ bg: 'gray.500', color: 'gray.200' }}
                                                                height={'30px'}
                                                                rounded={'0'}
                                                                boxShadow='md'
                                                                // onClick={() => handleClickAccordion(2)}
                                                                >
                                                                {/* <Box as="span" flex='1' textAlign='center'>
                                                                        {preHourFormatted}
                                                                        {preHourFormatted !== preAddHourFormatted && preAddHourFormatted !== undefined ? '...' + preAddHourFormatted : ''}
                                                                    </Box> */}
                                                                    <Box as="span" flex='2' textAlign='center' fontFamily={'mono'}>
                                                                        {/* <Badge width={'45px'} variant='solid' float={'left'} height={'25px'} rounded={'0'}></Badge> */}
                                                                        <Badge fontSize={'0.9em'} width={'60px'} colorScheme={'green'} bg={checkedColor} float={'right'} height={'26px'} rounded={'0'} textAlign={'center'} pt={'3px'} mt={'1px'} mr={'-14px'}>{checkUncheckText}</Badge>
                                                                        <Text fontSize={'18px'} variant='solid' float={'center'} fontWeight={'bold'} pt={'2px'}>
                                                                            {preHourFormatted}
                                                                            {preHourFormatted !== preAddHourFormatted && preAddHourFormatted !== undefined ? ' ' + preAddHourFormatted : ''}
                                                                        </Text>
                                                                    </Box>
                                                                {/* <AccordionIcon /> */}
                                                            </AccordionButton>
                                                            <AccordionPanel pl={0} pr={0} pb={0}>
                                                                {itemToRender}
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                        )
                                                    }
                                                    // }
                                                    hour = voucher.starthour;
                                                    hourFormatted = voucher.starthourformatted;
                                                } else if (hour !== '0000') {
                                                    addHourFormatted[voucher.starthour] = voucher.starthourformatted;
                                                    // addHourFormatted = addHourFormatted + ' - ' + voucher.starthourformatted
                                                    // console.log('item.length', item.length);
                                                }
                                                prevStartHour = voucher.starthour;
                                                // console.log('item', item);
                                            })}
                                        </Accordion>
                                        {/* <Box mb={'1000px'}></Box> */}
                                    </>
                                    :
                                    <>
                                        {/* <Flex justifyContent={'space-between'} mb={'10px'} border={'0px solid'} p={'4px'} rounded={'lg'} textAlign={'center'}>
                                            <HStack w={'100%'} textAlign={'center'}>
                                                <Box textAlign={'center'} alignContent={'center'}> */}
                                        <Center mt={'20px'}>
                                            <Text fontSize={'18px'} fontWeight={'bold'}>{results.success === false ? 
                                            <>
                                            {/* {results.error[0]} */}
                                                    <div dangerouslySetInnerHTML={{ __html: results.error[0] }} />
                                            <Text mt={'15px'}>{CONFIG.TEXT_LOGIN_QRCODE}</Text>
                                            <ActionButtons onClick={() => { history.push(CONFIG.QRCODE_LOGIN) }} text={CONFIG.BUTTON_LOGIN_QRCODE} />
                                            {/* <Text mt={'15px'}>Per poter utilizzare il software è necessario effetture la scansione del qrcode ADMIN consegnato</Text>
                                        <ButtonBox title={'Scansiona il QrCode'} icon={<FaCamera size={'1.3em'} />} link={CONFIG.QRCODE_LOGIN} /> */}
                                        </>
                                            : 'Nessun voucher presente'}</Text>
                                        </Center>
                                        {/* </Box>  
                                        </HStack>
                                    </Flex> */}
                                    </>
                            }
                        </Stat>
                    </SimpleGrid>
                }
            </Box>
            <Box maxW="7xl" mx={'auto'} pt={'100px'} px={{ base: 2, sm: 1, md: 1 }}>
                <List spacing={3} textAlign={'left'}>
                    <ListItem>
                        <Text display={'inline'}></Text>
                    </ListItem>
                </List>
            </Box>
            {/* <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered> */}
            <Modal
                // isCentered
                onClose={handleCloseModal}
                isOpen={isVoucherOpen}
                motionPreset='slideInBottom'
                scrollBehavior={'inside'}
                size={'full'}
                allowPinchZoom={true}
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    // backdropFilter='blur(5px)'
                />
                <ModalContent>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton size='sm' border={'none'} background={'hidden'} width={'10px'} padding={'20px'} />
                    <ModalBody width={ {base: '100%', sm: '100%', md: '500px'} } p={0} margin={'auto'}>
                        {results.success && <VoucherResultsApi result={results} viewPdf={viewPdf} />}
                    </ModalBody>

                    {/* <ModalFooter>
                            <Button colorScheme='gray' mr={3} onClick={handleCloseModal}>
                                Torna alla lista voucher
                            </Button> */}
                    {/* <Button variant='ghost'>Secondary Action</Button> */}
                    {/* </ModalFooter> */}
                </ModalContent>
            </Modal>
            <Modal
                // isCentered
                onClose={onTicketClose}
                isOpen={isTicketOpen}
                motionPreset='slideInBottom'
                scrollBehavior={'inside'}
                size={'full'}
                allowPinchZoom={true}
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    // backdropFilter='blur(5px)'
                />
                <ModalContent height={'100%'}>
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton size='sm' border={'none'} background={'teal'} width={'10px'} padding={'20px'} pl={'40px'} pr={'40px'} />
                    <ModalBody p={0}>
                        <iframe
                            src={pdfFileUrl}
                            // frameBorder="0"
                            // scrolling="auto"
                            height="100%"
                            width="100%"
                            title="pdfTicket"
                        ></iframe>
                        {/* <embed src="sample.pdf" frameborder="0" width="100%" height="400px"> */}
                    </ModalBody>

                    {/* <ModalFooter>
                            <Button colorScheme='gray' mr={3} onClick={handleCloseModal}>
                                Torna alla lista voucher
                            </Button> */}
                    {/* <Button variant='ghost'>Secondary Action</Button> */}
                    {/* </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    );

}

export default VoucherList;
