
// import { useSearchParams } from "react-router-dom";
// import { toastUser } from "./Notification";
// import * as API from './ApiUtilities'
// import { FaWindows } from "react-icons/fa";
// import { Code } from '@chakra-ui/react';
import { checkUser } from './ApiUtilities';
// import * as wUtils from '../utils/WalletUtils'

// export async function SessionToken() {
//     // const name = queryParams.get('name');
//     // const type = queryParams.get('type');
//     // console.log(id, name, type); // 55 test null

//     let token = localStorage.token;
//     if (!token) {
//         const queryParams = new URLSearchParams(window.location.search);
//         token = localStorage.token = queryParams.get('token');
//     //     token = () => {
//             // Math.random().toString(36).substr(-8)
//             // const [searchParams, setSearchParams] = useSearchParams();
//             // token = searchParams.get("token")
//             // console.log('token', token)
//     }
//     console.log('token', token);
//     const user = null;
//     if (token) {
//         AbtgAPI.loginWithToken(token).then((user) => {
//             // console.log(user);
//         //   if (user.success) {
//         //     // user.lastbalance = 10;
//         //     this.setState({
//         //       user: user,
//         //       success: user.success,
//         //       withdrawls: user.withdrawls
//         //     })
//         //     user.success ? Notification('Complete.', user.message[0], 'success') : Notification('Error.', user.error[0], 'error');
//         //     // Notification('Complete.', 'Data has been loaded.', 'success');
//         //   }
//         //   console.log(user);
//           // console.log('withdrawls', user.withdrawls);
//             // return { 'token': token, 'user': user };
//         })
//     }
//     return { 'token': token, 'user': user };
// }

export async function getOrSetVouchercode(vouchercode) {
  // console.log('IN vouchercode', vouchercode)
  if (vouchercode === undefined || vouchercode === 'undefined') {
    vouchercode = localStorage.vouchercode;
  } else {
    localStorage.vouchercode = vouchercode;
  }
  // console.log('OUT vouchercode', vouchercode)
  return { 'vouchercode': vouchercode };
}

export async function getAccordionIndex(accordionIndex) {
  // console.log('IN vouchercode', vouchercode)
  if (accordionIndex === undefined || accordionIndex === 'undefined') {
    accordionIndex = localStorage.accordionIndex;
  }
  return accordionIndex;
}

export async function setAccordionIndex(accordionIndex) {
  // console.log('IN vouchercode', vouchercode)
  if (accordionIndex === undefined || accordionIndex === 'undefined') {
    accordionIndex = localStorage.accordionIndex;
  } else {
    localStorage.accordionIndex = accordionIndex;
  }
  // console.log('OUT vouchercode', vouchercode)
  return accordionIndex;
}

export async function getCameraPermission() {
  localStorage.HTML5_QRCODE_DATA !== undefined ? console.log('getCameraPermission', JSON.parse(localStorage.HTML5_QRCODE_DATA).hasPermission) : console.log('getCameraPermission', false);
  return localStorage.HTML5_QRCODE_DATA !== undefined ? JSON.parse(localStorage.HTML5_QRCODE_DATA).hasPermission : false;
}

export async function loadUser() {
    const res = await checkUser();
  // console.log(res);
  localStorage.success = res.success;
  localStorage.error = res.success ? '' : res.error[0];
    // localStorage.nameuser = res.success ? res.user['name'] : '';
    // localStorage.success = res.success;
    // localStorage.error = res.success ? '' : res.error[0];
    // return res;
  if (res.success) return res.user
  else return undefined;
    // localStorage.apiKey = res.success ? localStorage.apiKey : undefined;
  // return localStorage.nameuser;
}

export async function getApikey() {
  let redirect = false;
  // let token = window.location.pathname.substring(1)
  // console.log('getToken token', token);
  // token=token.substring(1)
  // console.log('getToken token', token);
  // if (token) redirect = true;
    const queryParams = new URLSearchParams(window.location.search);
    let apiKey = queryParams.get('apiKey');
    // console.log('apiKey', apiKey)
  if (apiKey) {
    redirect = true;
    // localStorage.nameuser = undefined;
    // localStorage.nameuser = await getNameuser();
  }
  if (!apiKey) {
    apiKey = localStorage.apiKey;
    }
  localStorage.apiKey = apiKey !== undefined ? apiKey : '';
    // console.log('localStorage token', token);

  // console.log('getToken window.location.protocol+window.location.host', window.location.host);
    if (redirect) {
      // setTimeout(redirectUrl, 1000);
      window.location.href = window.location.protocol+'//'+window.location.host;
      // window.location.replace(window.location.host);
    };
  return { 'apiKey': apiKey };
}